import { Container, Row, Col } from "react-bootstrap";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractChainExplorer = process.env.REACT_APP_CONTRACT_CHAIN_EXPLORER;
const version = process.env.REACT_APP_VERSION;

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <>
      <footer className='bg-dark text-white py-4 text-center'>
      <Container>
        <p className="mb-1">Le projet The BULBiMasks est un projet indépendant à l'initiative de BullBiDoo qui bénéficie du soutien et des nombreux talents des membres de la communauté <a href="https://www.thecryptomasks.xyz/" target="_blank">TheCryptomasks</a>. Merci à son fondateur <a href="https://twitter.com/TheCryptomath" target='_blank'>TheCryptoMath</a> pour la confiance qu'il nous a témoigné en autorisant cette initiative 🤜🤛</p>
      </Container>

        <Container>
          <Row>
            <Col sm={4} md={4} className="pt-4 text-white">
              <h4>Le projet</h4>
              <span><small><a href="https://opensea.io/fr/collection/thebulbimasks" target="_blank">🌊 OpenSea</a></small></span><br />
              <span className="sc">Smart Contract : <a href={`https://${contractChainExplorer}/address/${contractAddress}`} target="_blank">{contractAddress}</a></span><br />
              <span className="made-with-love">DApp v.{version} made with <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank">RpGmAx</a>. Based on a <a href="https://twitter.com/DMDOO_eth" target="_blank">DMDOO</a> design idea.</span><br />
              <p className="mt-2"><small>Envie de nous payer un ☕ : <i>cafe.thebulbimasks.eth</i><br />⚠️ Tokens ERC-20 uniquement, pas de NFT</small></p>
            </Col>
            <Col sm={4} className="pt-4">
              <h4 className="text-white">BullBiDoo</h4>
              <ul className="list-unstyled">
                <li><a href="https://www.twitter.com/BullBiDoo" target="_blank">Twitter</a></li>
                <li><a href="https://discord.gg/yUDNQJfGng" target="_blank">Discord</a></li>
              </ul>
            </Col>
            <Col sm={4} className="pt-4">
              <h4 className="text-white">TheCryptoMasks</h4>
              <ul className="list-unstyled">
                <li><a href="https://www.twitter.com/TheCryptomasks" target="_blank">Twitter</a></li>
                <li><a href="https://discord.gg/EVCkNzAns2" target="_blank">Discord</a></li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className="mt-4 text-center">
                  <a className="btn btn-light mx-2 mb-2" href="#mint">👆 Go to Mint ⛏️</a>
                </p>
              </Col>
          </Row>
        </Container>
        
      </footer>
    </>
  )
}

export default Footer