import BannerWEBM from "../assets/banner.webm";
import BannerMP4 from "../assets/banner.mp4";
import BannerIMG from "../assets/banner.png";

const Banner = () => {

  return (<>
    <div className="banner-video">
    <video autoPlay loop muted playsInline poster={BannerIMG}>
       <source src={BannerWEBM} type="video/webm" />
       <source src={BannerMP4} type="video/mp4" />
     </video>
     <div className="banner-links">
       <ul className="list-inline m-0">
        <li className="list-inline-item"><a href="https://gitbook.thebulbimasks.xyz/" target="_blank" className="btn link-menu btn-sm d-none">📖 GitBook</a></li>
        <li className="list-inline-item"><a href="#mint" className="btn link-menu btn-sm">Mint</a></li>
        <li className="list-inline-item"><a href="#team" className="btn link-menu btn-sm">Team</a></li>
        <li className="list-inline-item d-none"><a href="#partners" className="btn link-menu btn-sm">Partenaires</a></li>
        <li className="list-inline-item "><a href="#contact" className="btn link-menu btn-sm">Contactez-nous</a></li>
        <li className="list-inline-item "><a href="https://opensea.io/fr/collection/thebulbimasks" target="_blank" className="btn link-menu btn-sm">🌊 OpenSea</a></li>
       </ul>
     </div>
   </div>
   </>
  );
};

export default Banner;