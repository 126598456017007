import { useState } from 'react';
import { Row, Col, Form, InputGroup, Button, ListGroup } from "react-bootstrap";

import toast, { Toaster } from 'react-hot-toast';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useNetwork, useSigner, useContractRead, useContractEvent } from 'wagmi';
import { ethers } from "ethers";

import tcpAbi from '../contracts/TCP-ABI.json';
import abi from '../contracts/ABI.json';

window.Buffer = window.Buffer || require('buffer').Buffer;

const tcpContractAddress = process.env.REACT_APP_TCP_CONTRACT_ADDRESS;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractChainName = process.env.REACT_APP_CONTRACT_CHAIN_NAME;
const contractChainId = parseInt(process.env.REACT_APP_CONTRACT_CHAIN_ID);

const Mint = () => {

  const [mintPaused, setMintPaused] = useState();
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork();
  const { data: signerData } = useSigner();
  const [userNFTs, setUserNFTs] = useState([])

  const [maskId, setMaskId] = useState(null);
  const [ethTip, setEthTip] = useState(0.005);
  const [tokenAlreadyMinted, setTokenAlreadyMinted] = useState();
  const [tokenJustMinted, setTokenJustMinted] = useState(null);

  // EVENTS
  // CurrentStep
  const getMintPauseStatus = useContractRead({
    address: contractAddress,
    abi: abi,
    functionName: 'mintPaused',
    chainId: contractChainId, onSuccess(data) { setMintPaused(data); }
  })

  const getUserNFTs = useContractRead({
    address: tcpContractAddress,
    abi: tcpAbi,
    functionName: 'walletOfOwner',
    args: [address],
    enabled: Boolean(address),
    onSuccess(data) {
      var nfts = String(data).split(',').filter(Boolean);
      if (nfts.length >= 1) { setUserNFTs(nfts) } else { setUserNFTs([]); }
    }
  })

  const refreshCurrrentStep = useContractEvent({
    address: contractAddress,
    abi: abi,
    eventName: 'mintPauseUpdated',
    chainId: contractChainId,
    listener(data) { setMintPaused(data); }
  })

const checkIfTokenAlreadyExists = async (tokenId) => {
  if (!signerData | !isConnected) return;
  if (chain.id != contractChainId) return;

  try {
      const nftContract = new ethers.Contract(contractAddress, abi, signerData);
      const mintedtokensInfos = await nftContract.mintedTokens(tokenId);
      setTokenAlreadyMinted(mintedtokensInfos);
  } catch (err) { console.log(err); }
}

const mintNftHandler = async (tokenId, price = 0) => {
  if (!signerData) return;
  try {
          const nftContract = new ethers.Contract(contractAddress, abi, signerData);
          let nftTxn;

          const toast_loading = toast.loading('Mint du BULBiMask #' + tokenId);

          price = price.toString();
            price = ethers.utils.parseEther(price);
            nftTxn = await nftContract.mint(tokenId, {value: price});

          toast.loading('Mint en cours', {id: toast_loading});
          await nftTxn.wait();

          toast.dismiss(toast_loading);
          toast.success('Mint réussi !');
          setTokenJustMinted(tokenId);

  } catch (err) {
      toast.dismiss();
      toast.error('Mint annulé !');
      if (err.code === 'UNPREDICTABLE_GAS_LIMIT') { toast.error('Erreur : Mint non autorisé'); }
      if (err.code === 'INSUFFICIENT_FUNDS') { toast.error('Erreur : Fonds insuffisants'); }
  }
}

const mintUI = () => {

  if (!isConnected) return (<><div className="connect-zone my-2"><ConnectButton chainStatus="none" label="Connecter son wallet" /></div></>);

  const optionItems = userNFTs.map((item, index) => <option key={index} value={item}>Cryptomask #{item}</option>);

  const handleMaskChange = (e) => {
    let selectedItem = parseInt(e.target.value);
    setTokenJustMinted(null);
    if (selectedItem >= 0) { setMaskId(selectedItem); checkIfTokenAlreadyExists(selectedItem); } else { setMaskId(null); }    
  }

  const handleTipChange = (e) => {
    const inputValue = e.target.value;
    try {
      ethers.utils.parseEther(inputValue);
      if (inputValue >= 0.005) { setEthTip(inputValue); } else { setEthTip(0.005); }
    } catch (error) { setEthTip(0.005); }
  };

  return (<>
    { chain.id !== contractChainId ? ( <><p>Veuillez passer sur le réseau {contractChainName}</p><div className="connect-zone my-2"><ConnectButton label="Connecter son wallet" /></div></> )
      : (<>
    <Col xl={4} xxl={3} className="my-2"><ConnectButton chainStatus="none" label="Connecter son wallet" /></Col>
    <Col className="my-2">

      <InputGroup>
      <InputGroup.Text>Numéro de 🎭</InputGroup.Text>
      <Form.Select onChange={handleMaskChange}>
      {userNFTs && userNFTs.length >= 1 ? (<>
        <option value="">Sélectionnez le mask souhaité</option>
        {optionItems}
        </>) : <option>Aucun mask trouvé sur ce wallet - Vous devez mint avec le wallet contenant votre mask</option> } 
        </Form.Select>
      </InputGroup>  
    </Col>

    {tokenJustMinted != null ? (<>
    <Col xxl={12} className="my-2">
    <div className="alert alert-success p-1 mb-2" role="alert">Félicitations votre <strong>BULBiMask</strong> est bien arrivé dans votre wallet ! 🥳</div>
      <a className="btn btn-sm btn-primary my-2 mx-1" target="_blank" href={`https://opensea.io/fr/assets/ethereum/${contractAddress}/${tokenJustMinted}`}>🌊 Le découvrir sur OpenSea</a>
      <a className="btn btn-sm btn-danger my-2 mx-1" target="_blank" href="https://twitter.com/intent/tweet?text=Je%20viens%20de%20minter%20mon%20%23BULBiMasks%E2%80%8E%F0%9F%A5%B0%0A%0AMerci%20%C3%A0%20%40Bull_Biz_Art,%20%40DMDOO_eth,%20%40RpGmAx%20et%20%40Kirk_4rt%20%F0%9F%99%8F%0A%0ASi%20toi%20aussi%20tu%20es%20holder%20%40TheCryptomasks%20RDV%20sur%20thebulbimasks.xyz%20%E2%9B%8F%EF%B8%8F%0A%0ASinon%20follow%20%40BullBiDoo%F0%9F%A4%90%0A%0A%23WeAreCryptomasks%20%F0%9F%8E%AD">Dire merci sur Twitter 🙏</a>
    </Col>
    </>) : null }

    {tokenAlreadyMinted == false && tokenJustMinted == null ? (<>
    <Col xxl={4} className="my-2">
      {maskId != null ? (<>

      <InputGroup className="mb-3">
      <InputGroup.Text id="app-title">Je paye mon ☕</InputGroup.Text>

        <Form.Control type="number" min="0.005" step="0.005" value={ethTip} onChange={handleTipChange} />
        <InputGroup.Text>ETH</InputGroup.Text>
        <Button className="btn-danger" onClick={() => {mintNftHandler(maskId, ethTip).then()}}>Mint ⛏</Button>
      </InputGroup>

      <small><i>Des litres de café ont été nécessaires au développement de ce projet.<br />Vous pouvez participer, lors du mint ou à tout moment sur notre wallet dédié (ETH ou autre) - <strong>cafe.thebulbimasks.eth</strong> - ou utiliser le bouton <strong>Free Mint</strong>.</i></small>
      
      </>) : null }

    </Col>
    <Col xxl={2} className="my-2">

    {maskId != null ? (<>
      {tokenAlreadyMinted == false ? (<Button className="btn-light" onClick={() => {mintNftHandler(maskId).then()}}>Free Mint ⛏</Button>) : null}
    </>) : null}

    </Col>

    </>) : null}

    {tokenAlreadyMinted == true ? (<Col xxl={2} className="my-2"><span className="btn btn-danger">Ce BULBiMask a déjà été minté ⛏</span></Col>) : null }

      </>) // Réseau OK
    }
      </>) // Return
}

  return (<>
	<div className="mb-4"><h2>Mint {isConnected == true && chain.id == contractChainId ? (<>
    {mintPaused == true ? (<><small className="text-danger">FERMÉ</small></>) : (<><small className="text-success">OUVERT</small></>)}
    </>) : null }</h2></div>

    <Row>
      <ListGroup horizontal className="p-0">
        <ListGroup.Item><span className="badge rounded-pill text-bg-warning m-1 float-start">1</span>Connectez votre <b>wallet</b> 🦊</ListGroup.Item>
        <ListGroup.Item><span className="badge rounded-pill text-bg-warning m-1 float-start">2</span>Sélectionnez votre <b>mask</b> 🎭 dans la liste</ListGroup.Item>
        <ListGroup.Item><span className="badge rounded-pill text-bg-warning m-1 float-start">3</span>Cliquez sur <b>Mint ⛏</b> ou sur <b>Free Mint ⛏</b></ListGroup.Item>
      </ListGroup>
    </Row>

    <Row className="mt-4">{mintUI()}</Row>
  </>
  );
};

export default Mint;