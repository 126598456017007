import { Row, Col, Card, ButtonGroup, Button, Alert } from "react-bootstrap";

import team from '../assets/team.jpg';

const Team = () => {

return (<>
<Row>
    <Col className="my-4">
        <Card className="shadow-lg">
            <Card.Img variant="top" src={team} />
            <Card.Body>
                <p className="text-center"><strong>BullBi</strong> voulait faire un peu de bruit autour de notre projet préféré 🎭</p>
                <p className="text-center">Ça s'est un peu <strong>emballé</strong> ! 🔥</p>
                <p className="text-center">L'équipe était déjà montée pour le <strong>projet #cB 💪</strong></p>
                <p className="text-center">Alors on a décidé de <strong>vous régaler</strong> !</p>
               
                <Alert variant="warning" className="text-center text-dark">
                    Les <strong>BULBiMasks</strong> sont créés à la demande.< br/>Une centaine est déjà disponible, les autres seront créés dès votre NFT minté.<br />Surveillez <a target="_blank" href="https://twitter.com/BullBiDoo" className="btn-link text-danger">@BullBiDoo</a>, les prochaines fournées seront annoncées !
                </Alert>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <ButtonGroup size="sm">
                        <Button variant="light" href="https://twitter.com/Kirk_4rt" target="_blank"><span className="d-block d-sm-inline">🎨</span> @Kirk_4rt</Button>
                        <Button variant="light" href="https://twitter.com/BullBiDoo" target="_blank"><span className="d-block d-sm-inline">🧠</span> @BullBiDoo</Button>
                        <Button variant="light" href="https://twitter.com/RpGmAx" target="_blank"><span className="d-block d-sm-inline">💻</span> @RpGmAx </Button>
                    </ButtonGroup>
                </Row>
            </Card.Footer>
        </Card>
    </Col>
</Row>
  </>
  );
};

export default Team;