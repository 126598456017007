import { Container, Row, Col } from "react-bootstrap";

import Header from "../components/Header";
import Banner from "../components/Banner";
import Mint from "../components/Mint";
import Team from "../components/Team";
import Masks from "../components/Masks";
import Partners from "../components/Partners"; 
import Contact from "../components/Contact"; 

import Meta from '../components/Meta'

const Home = () => {

  const pageTitle = 'The BULBiMasks'

  return (<>      
  
    <Header />
      <Meta title={pageTitle}/>

      <Banner />

      <Container>
        <Row>
          <Col lg={12} className="text-center my-4">
            <h1>The BULBiMasks</h1>
            <p className="card-text">Une <strong>collection #NFT</strong> créée par des Holders du projet <strong className="text-nowrap">TheCryptomasks 🎭</strong></p>
          </Col>
        </Row>
      </Container>

      <Container fluid className="mint-zone p-4 bg-dark" id="mint">
        <Mint />        
      </Container>

      <Container id="team">
        <Team />
      </Container>

      <Container fluid className="py-3 bg-dark" id="masks">
        <Masks />
      </Container>

      { 1 == 1 ? (<></>) : (
      <Container fluid className="py-3" id="partners">
        <Partners />
      </Container>
      ) }

      <Container className="py-3" id="contact">
        <Contact/>
      </Container>

    </>
  )
}

export default Home