import { Container, Navbar } from 'react-bootstrap';

import Logo from "../assets/bm.png";

const Header = () => {

  return (
    <header>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo} height="50" className="me-2 mb-2" />
            <strong>BULBiMasks</strong>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;