import { Row, Col, Card, ButtonGroup, Button } from "react-bootstrap";

const Contact = () => {

return (<>

<Row className="justify-content-center">

<Col sm={5} className="my-4">
    <Card className="shadow mb-4">
        <Card.Title className="text-center mt-4">Contactez-nous !</Card.Title>
        <Card.Body>
            <p className="text-center">Vous partagez nos valeurs et voulez de la visibilité auprès des communautés les plus actives du CTFR ?</p>
            <p className="text-center">Vous savez où nous trouver 😉</p>
        </Card.Body>
        <Card.Footer>
            <Row>
                <ButtonGroup size="sm">
                    <Button variant="light" href="https://twitter.com/BullBiDoo" target="_blank">🐦 Twitter</Button>
                </ButtonGroup>
            </Row>
        </Card.Footer>
    </Card>
</Col>

</Row>

  </>
  );
};

export default Contact;