import { Row, Col, Card, ButtonGroup, Button } from "react-bootstrap";

import genuine from '../assets/cryptomasks_official.jpg';
import honorary from '../assets/cryptomasks_honorary.jpg';
import collabs from '../assets/cryptomasks_collabs.jpg';
import collabsEggs from '../assets/cryptomasks_collabs_eggs.jpg';

const Masks = () => {

const today = new Date();
const isEggsDay = today.getMonth() === 3 && today.getDate() === 9;

return (<>

<Row>

<Col sm={12} md={4} className="my-4">
    <Card className="h-100 border-dark">
        <Card.Img variant="top" src={genuine} />
        <Card.Title className="collection">Les Genuine</Card.Title>
        <Card.Body>
            <p className="text-center">Déviation 1/1 de la collection officielle TheCryptomasks</p>
            <p className="text-center"><small>Holder de Cryptomasks Officiels, vous pouvez mint gratuitement votre BULBiMask <a href="#mint" className="text-decoration-none">ici</a>.</small></p>
        </Card.Body>
        <Card.Footer>
            <Row>
                <ButtonGroup size="sm">
                    <Button variant="light" href="https://opensea.io/collection/thecryptomasksproject" target="_blank">🌊 TheCryptomasks Project</Button>
                </ButtonGroup>
            </Row>
        </Card.Footer>
    </Card>
</Col>

<Col m={12} md={4} className="my-4">
    <Card className="h-100 border-dark">
        <Card.Img variant="top" src={honorary} />
        <Card.Title className="collection">Les Honorary</Card.Title>
        <Card.Body>
            <p className="text-center">Déviation 1/1 de la collection Honorary TheCryptomasks</p>
            <p className="text-center"><small>Holder de Cryptomasks Honorary, demandez votre BULBiMask en DM @BullBiDoo.</small></p>
        </Card.Body>
        <Card.Footer>
            <Row>
                <ButtonGroup size="sm">
                    <Button variant="light" href="https://opensea.io/collection/the-cryptomasks-honorary" target="_blank">🌊 TheCryptomasks Honorary</Button>
                </ButtonGroup>
            </Row>
        </Card.Footer>
    </Card>
</Col>

<Col m={12} md={4} className="my-4">
    <Card className="h-100 border-dark">
        <Card.Img variant="top" src={isEggsDay ? collabsEggs : collabs} />
        <Card.Title className="collection">Les Collabs</Card.Title>
        <Card.Body>
            <p className="text-center">Déviation 1/1 de la collection Collabs TheCryptomasks</p>
            <p className="text-center"><small>Holder de Cryptomasks Collabs, demandez votre BULBiMask en DM @BullBiDoo.</small></p>
        </Card.Body>
        <Card.Footer>
            <Row>
                <ButtonGroup size="sm">
                    <Button variant="light" href="https://opensea.io/collection/the-cryptomasks-collabs" target="_blank">🌊 TheCryptomasks Collabs</Button>
                </ButtonGroup>
            </Row>
        </Card.Footer>
    </Card>
</Col>

</Row>

  </>
  );
};

export default Masks;